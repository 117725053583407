import React, { useState, useEffect, useContext } from 'react';
import { Context as AppContext } from "../context/appContext";
import BatchInfoComponent from './BatchInfoComponent';

const Devices = () => {
  const { state, getDevices } = useContext(AppContext);

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <div>
      {state.devices ? state.devices.map((device) => (
        <div key={device.terminal_id}>
          <h2>{device.terminal_name}</h2>
          <BatchInfoComponent terminal={device} />
        </div>
      )) : <div>No devices found call Tysoft 765-405-0098</div>}
    </div>
  );
};

export default Devices;
