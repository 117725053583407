import React, { useState, useEffect, useContext } from "react";
import { Context as AppContext } from "../context/appContext";
import "./BatchInfoComponent.css";
const BatchCard = ({ batch }) => (
  <div className="batch-card">
    <h3>Batch ID: {batch.id}</h3>
    <div className="batch-details">
      <p>
        <strong>Status:</strong> {batch.status}
      </p>
      <p>
        <strong>Terminal:</strong> {batch.terminal_name} ({batch["terminal-id"]}
        )
      </p>
      <p>
        <strong>Date Opened:</strong>{" "}
        {new Date(batch["date-opened"]).toLocaleString()}
      </p>
      {batch.settled && (
        <p>
          <strong>Date Settled:</strong>{" "}
          {new Date(batch.settled).toLocaleString()}
        </p>
      )}
      <p>
        <strong>Sales:</strong> {batch["sales-count"]} ($
        {parseFloat(batch["sales-total"]).toFixed(2)})
      </p>
      <p>
        <strong>Refunds:</strong> {batch["refund-count"]} ($
        {parseFloat(batch["refund-total"]).toFixed(2)})
      </p>
      <p>
        <strong>Net Amount:</strong> $
        {parseFloat(batch["net-amount"]).toFixed(2)}
      </p>
      {batch["confirmation-code"] && (
        <p>
          <strong>Confirmation Code:</strong> {batch["confirmation-code"]}
        </p>
      )}
    </div>
  </div>
);

const BatchInfoComponent = (terminal) => {
  console.log(terminal);
  const { state } = useContext(AppContext);
  const [batches, setBatches] = useState([]);
  const getBatches = async () => {
    try {
      const response = await fetch(
        `/api/terminals/${terminal.terminal.terminal_id}/batches`,
        {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log(data);
        setBatches(data.batch);
      } else {
        throw new Error(data.error || "Failed to fetch batches");
      }
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  useEffect(() => {
    getBatches();
  }, [terminal.terminal.terminal_id]);
  return (
    <div className="batch-info-container">
      <h1>Batch Information</h1>
      <div className="batch-carousel">
        <div className="batch-list">
          {batches.length > 0 ? (
          batches.map((batch) => <BatchCard key={batch.id} batch={batch} />)
        ) : (
          <p>No batches found.</p>
        )}
        </div>
      </div>
    </div>
  );
};

export default BatchInfoComponent;
