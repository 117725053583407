import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import BathRenovation from "./components/BathRenovation.jsx";
import UserInfoConponent from "./components/userInfo.js";
import LoginLink from "./components/LoginLink.js";
import { Context as AppContext } from "./context/appContext.js";
// import OAuthCallback from "./components/oauthCallback.js";
import NavBar from "./components/navBar.js";
import Logout from "./components/logOut.js";
import InvoicesView from "./components/InvoicesView.js";
import InvoicePreview from "./components/invoicePreview.js";
import CustomersComponent from "./components/CustomersComponent.js";
import PaymentScreen from "./components/PaymentScreen.js";
import Dashboard from "./components/Dashboard.js";
import MerchantSignUp from "./components/MerchantSignUp.js";

const App = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { state, checkAuthCookie, setAuthToken } = useContext(AppContext);
  // console.log(state);
  // const params = new URLSearchParams(window.location.search);
  // const authToken = params.get('authToken');

  useEffect(() => {
    // checkAuthCookie();
    let params = new URLSearchParams(window.location.search);
    let authToken = params.get("authToken");
    if (authToken) {
      console.log("Auth Token found:", authToken);
      setAuthToken(authToken).then(() => {
        window.location.href = "/";
      });
    }
    checkAuthCookie();
  }, []);

  if (!state.authToken) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginLink />} />
        </Routes>
      </Router>
    );
  }
  if (!state.merchant) {
    return <MerchantSignUp />;
  }

  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row">
          <main className="col-md-10 ms-sm-auto col-lg-10 ">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              {/* <Route path="/reports" element={<Reports />} /> */}
              <Route path="/payments" element={<PaymentScreen />} />
              <Route path="/customers" element={<CustomersComponent />} />
              {/* <Route path="/bath-renovation" element={<BathRenovation />} /> */}
              <Route path="/userinfo" element={<UserInfoConponent />} />
              <Route path="/invoices" element={<InvoicesView />} />
              <Route path="/invoice/:invoiceId" element={<InvoicePreview />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
};

export default App;
