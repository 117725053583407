import React, { useContext, useEffect } from "react";
import { Context as AppContext } from "../context/appContext";
import LogOut from "./logOut";
import Devices from "./Devices";

const Dashboard = () => {
  const { state, getCustomers } = useContext(AppContext);

  useEffect(() => {
    getCustomers();
  }, []);

  if (state.apiError) {
    return (
      <>
        <LogOut />
      </>
    );
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <Devices />
    </div>
  );
};

export default Dashboard;
